import request from "../plugins/axios";

export function workEmployee(params) {
    return request({
        url: '/workContact/contactStatistics',
        method: 'GET',
        params
    })
}
export function roomStatistics(params) {
    return request({
        url: '/workRoom/roomStatistics',
        method: 'GET',
        params
    })
}
export function statisticsCode(params) {
    return request({
        url: '/channelCode/statistics',
        method: 'GET',
        params
    })
}
export function statisticsIndexCode(params) {
    return request({
        url: '/channelCode/statisticsIndex',
        method: 'GET',
        params
    })
}
